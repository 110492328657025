import {
  FilePdfTwoTone,
  FileTextTwoTone,
  FileWordTwoTone,
  FileZipTwoTone,
  FolderTwoTone,
  PictureTwoTone,
} from '@ant-design/icons';
import { FC } from 'react';
export interface FileIconProps extends React.HTMLAttributes<HTMLElement> {
  fileType: string;
  twoToneColor?: string;
}
const FileIcon: FC<FileIconProps> = ({
  fileType,
  twoToneColor = '#FF4D4F',
  ...props
}) => {
  switch (fileType) {
    case 'pdf':
      return <FilePdfTwoTone {...props} twoToneColor="#FF4D4F" />;
    case 'doc':
    case 'docx':
      return <FileWordTwoTone {...props} twoToneColor="#1890FF" />;
    case 'zip':
    case 'rar':
      return <FileZipTwoTone {...props} />;
    case 'folder':
      return <FolderTwoTone {...props} twoToneColor="#7AB32B" />;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <PictureTwoTone {...props} />;
    default:
      return <FileTextTwoTone {...props} twoToneColor={twoToneColor} />;
  }
};

export default FileIcon;
