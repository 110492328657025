import { List, Row, Col, Checkbox } from 'antd';
import { FC } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { File } from '@/interfaces/files.interface';
import { getFileInfo } from '@/utils/files';
import FileIcon from './FileIcon';
import FileHover from './FileHover';
import { PaginationConfig } from 'antd/es/pagination';

export interface FileListProps {
  files?: File[];
  loading?: boolean;
  layout?: 'list' | 'grid';
  pagination?: PaginationConfig;
  form?: boolean;
  formProps?: {
    value: string | string[];
    dataIndex: keyof File;
    multiple?: boolean;
    onChange: (value: string, item: File) => void;
  };
  onDelete?: (file: File) => Promise<void>;
  onPreview?: (file: File) => void;
}

const FileList: FC<FileListProps> = ({
  files = [],
  loading = false,
  form,
  formProps = {
    value: '',
    dataIndex: '_id',
    onChange: () => ({}),
  },
  onDelete,
  onPreview,
  layout = 'list',
  pagination,
}) => {
  return (
    <List
      {...(layout === 'grid' && {
        grid: {
          gutter: 0,
        },
      })}
      className={layout === 'grid' ? 'list-layout-by-grid' : 'list-layout-by-list'}
      pagination={pagination}
      dataSource={files}
      loading={loading}
      renderItem={(item) => {
        const { extension } = getFileInfo(item.real_name);
        const CheckboxElement = (
          <Checkbox
            checked={
              !formProps.multiple
                ? formProps?.value === item[formProps.dataIndex]
                : formProps?.value.includes(item[formProps.dataIndex] as string)
            }
            onChange={(e) => {
              if (formProps?.multiple) {
                let value = formProps?.value as string[];
                if (!Array.isArray(value)) {
                  if (value === '') {
                    value = [];
                  } else {
                    value = [value];
                  }
                }
                if (e.target.checked) {
                  if (!value.includes(item[formProps.dataIndex] as string)) {
                    formProps?.onChange?.(
                      [...value, item[formProps.dataIndex]] as unknown as string,
                      item,
                    );
                  }
                } else {
                  formProps?.onChange?.(
                    value.filter(
                      (v) => v !== item[formProps.dataIndex],
                    ) as unknown as string,
                    item,
                  );
                }
                return;
              }
              formProps?.onChange?.(
                e.target.checked ? (item[formProps.dataIndex] as string) : '',
                item,
              );
            }}
          />
        );
        if (layout === 'grid') {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <FileHover
                    name={item.name}
                    onPreview={() => onPreview?.(item)}
                    onDelete={() => onDelete?.(item)}
                  >
                    {item.type.includes('image') ? (
                      <div
                        className="item-fake-backgound"
                        style={{
                          backgroundImage: `url(${item.path})`,
                        }}
                      ></div>
                    ) : (
                      <FileIcon fileType={extension} style={{ fontSize: 200 }} />
                    )}
                    {form && (
                      <div
                        style={{ position: 'absolute', top: 20, left: 20, zIndex: 999 }}
                      >
                        {CheckboxElement}
                      </div>
                    )}
                  </FileHover>
                }
              />
            </List.Item>
          );
        }

        return (
          <List.Item
            actions={[
              <DeleteFilled
                onClick={() => onDelete?.(item)}
                key="delete"
                style={{ cursor: 'pointer', color: 'red' }}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Row gutter={[10, 10]} align="middle">
                  {form && <Col>{CheckboxElement}</Col>}
                  <Col>
                    <FileHover
                      name={item.name}
                      onPreview={() => onPreview?.(item)}
                      styleProps={{ cursor: 'pointer', height: 60, width: 60 }}
                      hideName
                      iconProps={{ style: { fontSize: 20 } }}
                    >
                      {item.type.includes('image') ? (
                        <div
                          style={{
                            backgroundImage: `url(${item.path})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            height: '60px',
                            width: '60px',
                          }}
                        ></div>
                      ) : (
                        <FileIcon fileType={extension} style={{ fontSize: 60 }} />
                      )}
                    </FileHover>
                  </Col>
                </Row>
              }
              description={
                <>
                  <span>{item?.user?.full_name}</span>
                  <br />
                  <em>{item.type}</em>
                </>
              }
              title={item.name}
            />
            <div>
              <Row>
                <Col>{dayjs(item.created_at).format('dddd, MMMM, YYYY hh:mm')}</Col>
              </Row>
            </div>
          </List.Item>
        );
      }}
    />
  );
};

export default FileList;
