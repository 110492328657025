import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload';

const { Dragger } = Upload;

interface UploadModalProps {
  visible: boolean;
  multiple?: boolean;
  onFinish?: (file: File, fileList: File[]) => void;
  onCancel: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({
  visible,
  multiple,
  onFinish,
  onCancel,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  return (
    <Modal
      title={t('files.upload')}
      open={visible}
      centered
      onCancel={() => {
        onCancel();
        setSelectedFile(null);
        setFileList([]);
      }}
      onOk={async () => {
        setLoading(true);
        if (selectedFile) {
          await onFinish?.(selectedFile, fileList);
        }
        setLoading(false);
        setSelectedFile(null);
        setFileList([]);
      }}
      okButtonProps={{ disabled: !selectedFile, loading }}
    >
      <Dragger
        name="file"
        multiple={multiple}
        onRemove={() => setFileList([])}
        fileList={fileList}
        beforeUpload={(file, FileList) => {
          setSelectedFile(file);
          setFileList(FileList);
          return false;
        }}
        accept="image/*, .pdf, video/*"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('files.message')}</p>
      </Dragger>
    </Modal>
  );
};

export default UploadModal;
