const IMAGE_TYPE = ['jpg', 'jpeg', 'png', 'bmp', 'webp'];
const DOCUMENT_TYPE = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'];
export const getFileInfo = (name = '') => {
  const names = name.split('.');
  return {
    extension: names[names.length - 1],
    filetype: getFileType(names[names.length - 1]),
  };
};

export const getFileType = (extension = '') => {
  if (IMAGE_TYPE.includes(extension)) {
    return 'image/' + extension;
  }
  if (DOCUMENT_TYPE.includes(extension)) {
    return 'application/' + extension;
  }
  return 'others';
};
