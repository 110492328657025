import React, { useState, CSSProperties } from 'react';
import { SearchOutlined, DeleteFilled } from '@ant-design/icons';
import { Button } from 'antd';

export interface FileHoverProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>;
  styleProps?: CSSProperties;
  iconProps?: React.HTMLAttributes<HTMLDivElement>;
  hideName?: boolean;
  onPreview: () => void;
  onDelete?: () => void;
}

const FileHover: React.FC<FileHoverProps> = ({
  name = '',
  children,
  styleProps,
  iconProps,
  hideName = false,
  onPreview,
  onDelete,
  ...props
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="file-hover"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      {hover && (
        <>
          {hideName ? (
            <button onClick={onPreview} className="bg-hover fadeIn" style={styleProps}>
              <SearchOutlined {...iconProps} />
            </button>
          ) : (
            <div className="bg-hover fadeIn" style={styleProps}>
              <div className="action-buttons">
                <Button type="link" onClick={onPreview}>
                  <SearchOutlined {...iconProps} />
                </Button>
                <Button type="link" onClick={onDelete}>
                  <DeleteFilled {...iconProps} />
                </Button>
              </div>
              <p>{name}</p>
            </div>
          )}
        </>
      )}
      {children}
    </div>
  );
};
export default FileHover;
