import { FC, useState } from 'react';
import {
  Col,
  Row,
  Radio,
  Button,
  Input,
  message,
  Modal,
  Divider,
  Pagination,
} from 'antd';
import { AppstoreOutlined, MenuOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import FileList from './FileList';
import APIService from '@/services/API';
import UploadModal from './UploadModal';
import { File as FileI } from '@/interfaces/files.interface';
import PreviewFileModal from './PreviewFileModal';
export type Layout = 'list' | 'grid';
export interface FilesWrapperProps {
  initialLayout?: Layout;
  form?: boolean;
  formProps?: {
    value: string | string[];
    dataIndex: keyof FileI;
    multiple?: boolean;
    onChange: (value: string, file: FileI) => void;
  };
}

const INITIAL_PARAMS = {
  '$sort[created_at]': '-1',
  $skip: '0',
  $limit: '12',
};

const FilesWrapper: FC<FilesWrapperProps> = ({
  initialLayout = 'list',
  form,
  formProps = {
    value: '',
    dataIndex: '_id',
    multiple: false,
    onChange: () => ({}),
  },
}) => {
  // Hooks
  const { t } = useTranslation();
  // Application State
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [layout, setLayout] = useState<Layout>(initialLayout);
  const [uploadModal, setUploadModal] = useState(false);
  const [previewModal, setPreviewModal] = useState<{
    open: boolean;
    file?: FileI | null;
  }>({
    open: false,
    file: null,
  });

  const { data, isLoading, refetch } = useQuery(['files', params], () => {
    return APIService.getFiles(params);
  });

  // Application Logic
  const onSearch = (value: string) => {
    setParams((data) => {
      // Search files by name
      if (!value) {
        return INITIAL_PARAMS;
      }
      return { ...data, 'name[$regex]': value, 'name[$options]': 'i' };
    });
  };

  const handleUploadFile = async (file: File, fileList: File[]) => {
    if (formProps?.multiple) {
      const fileSelected = [];
      for (const file of fileList) {
        const response = await APIService.uploadFile(file);
        if (response.ok) {
          if (response.data) {
            fileSelected.push(response.data[formProps?.dataIndex] as string);
          }
        } else {
          message.error(t('files.error'));
        }
      }
      formProps?.onChange(fileSelected as unknown as string, null as unknown as FileI);
    } else {
      const response = await APIService.uploadFile(file);
      if (response.ok) {
        if (response.data) {
          formProps?.onChange(
            response.data[formProps?.dataIndex] as string,
            response.data,
          );
        }
      } else {
        message.error(t('files.error'));
      }
    }
    setUploadModal(false);
    refetch();
  };

  const handleDeleteFile = async (file: FileI) => {
    Modal.confirm({
      title: t('files.delete'),
      type: 'warning',
      centered: true,
      okButtonProps: {
        color: 'red',
      },
      okText: t('general.yes'),
      onOk: async () => {
        const response = await APIService.deleteFile(file._id);
        if (response.ok) {
          refetch();
        } else {
          message.error(t('files.error'));
        }
      },
    });
  };

  return (
    <>
      <UploadModal
        multiple={formProps?.multiple}
        visible={uploadModal}
        onCancel={() => setUploadModal(false)}
        onFinish={handleUploadFile}
      />
      <PreviewFileModal
        centered
        footer={[
          <Button
            type="primary"
            key="close"
            onClick={() => setPreviewModal({ open: false, file: null })}
          >
            {t('general.close')}
          </Button>,
        ]}
        width={800}
        file={previewModal.file || undefined}
        open={previewModal.open}
        title={previewModal.file?.name}
        onCancel={() => setPreviewModal({ open: false, file: null })}
      />
      <Row className="file-row-wrapper" wrap={false}>
        <Col flex="none">
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} md={12} lg={6}>
              <Row gutter={[10, 10]}>
                <Col>
                  <Radio.Group
                    options={[
                      {
                        label: <MenuOutlined />,
                        value: 'list',
                      },
                      {
                        label: <AppstoreOutlined />,
                        value: 'grid',
                      },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(value) => setLayout(value.target.value)}
                    value={layout}
                  />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={() => setUploadModal(true)}
                  >
                    {t('files.upload')}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12} lg={10}>
              <Pagination
                style={{ textAlign: 'center' }}
                total={data?.total}
                pageSize={data?.limit || 12}
                showSizeChanger={false}
                current={
                  parseInt(params.$skip || '0', 10) / parseInt(params.$limit || '0', 10) +
                  1
                }
                onChange={(page) => {
                  setParams((data) => {
                    return {
                      ...data,
                      $skip: ((page - 1) * parseInt(data.$limit || '0', 10)).toString(),
                    };
                  });
                }}
              />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Input.Search
                placeholder={t('general.search') || 'Search'}
                onSearch={onSearch}
                allowClear
              />
            </Col>
          </Row>
          <Divider></Divider>
        </Col>
        <Col
          flex="auto"
          className="file-container"
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          <FileList
            loading={isLoading}
            files={data?.data}
            onDelete={handleDeleteFile}
            layout={layout}
            onPreview={(file) => {
              setPreviewModal({ open: true, file });
            }}
            {...{ form, formProps }}
          />
        </Col>
      </Row>
    </>
  );
};

export default FilesWrapper;
