import React from 'react';
import { Divider, Button, Modal, ModalProps, Image, Descriptions, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { File as FileI } from '@/interfaces/files.interface';
import FileIcon from './FileIcon';
import { getFileInfo } from '@/utils/files';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
export interface PreviewFileModalProps extends ModalProps {
  file?: FileI;
}
const PreviewFileModal: React.FC<PreviewFileModalProps> = ({ file, ...props }) => {
  const { extension } = getFileInfo(file?.real_name as string);
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const copyToClipboard = async (text: string): Promise<boolean> => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text).then(() => true);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleCopy = (text: string) => {
    copyToClipboard(text)
      .then(() => {
        messageApi.info(t('files.copy'));
      })
      .catch((error) => {
        console.error('[copyToClipboard] Error to copy: ', error);
      });
  };

  const slicePath = (text: string) => {
    return `${text.substring(0, 20)} ... ${text.substring(
      text.length - 20,
      text.length,
    )}`;
  };

  return (
    <Modal {...props}>
      {contextHolder}
      <div style={{ textAlign: 'center' }}>
        {file?.type.includes('image') ? (
          <Image src={file?.path} height={300} />
        ) : (
          <FileIcon fileType={extension} style={{ fontSize: 200 }} />
        )}
      </div>
      <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
      <Descriptions column={1} className="file-information">
        <Descriptions.Item label={t('users.name')}>{file?.name}</Descriptions.Item>
        <Descriptions.Item label={t('general.type')}>{file?.type}</Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ alignSelf: 'center' }}
          contentStyle={{ alignItems: 'center' }}
          label={t('general.publicUrl')}
        >
          <a href={file?.path}>{slicePath(file?.path || '')}</a>
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => handleCopy(file?.path || '')}
          ></Button>
        </Descriptions.Item>
        <Descriptions.Item label={t('general.uploadedBy')}>
          {file?.user?.full_name}
        </Descriptions.Item>
        <Descriptions.Item label={t('general.uploadedOn')}>
          {dayjs(file?.created_at).format('dddd, MMMM, YYYY hh:mm')}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default PreviewFileModal;
